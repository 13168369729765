body {
  padding: 0;
  margin: 0;
  background-color: #f9f9f9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form-group {
  margin-bottom: 15px;
}

.text-right {
  text-align: right;
}

.one-button-column {
  width: 40px;
  text-align: center;
}

.two-buttons-column {
  width: 90px;
  text-align: center;
}

.three-buttons-column {
  width: 120px;
  text-align: center;
}

.four-buttons-column {
  width: 160px;
  text-align: center;
}

.five-buttons-column {
  width: 200px;
  text-align: center;
}

.six-buttons-column {
  width: 240px;
  text-align: center;
}

.seven-buttons-column {
  width: 280px;
  text-align: center;
}

.eight-buttons-column {
  width: 320px;
  text-align: center;
}

.btn-primary {
  background-color: #00c58f;
  border-color: #00c58f;
}

.btn-primary:hover {
  background-color: #0ca275;
  border-color: #0ca275;
}

.btn-primary:focus-visible,
.btn-primary:active {
  background-color: #0ca275 !important;
  border-color: #0ca275 !important;
  box-shadow: 0 0 0 0.25rem rgb(0 197 143 / 25%) !important;
}

.table-btn {
  padding: 0;
  width: 30px;
  height: 27px;
  text-align: center;
  vertical-align: middle;
  margin: 1px 2px;
}

.pull-right {
  float: right;
}

a {
  color: #00c58f;
}

a:hover {
  color: #007a59;
}

.form-control:focus {
  border-color: #00c58f;
  box-shadow: 0 0 0 0.25rem rgb(0 197 143 / 25%);
}

.bread-crumbs svg {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.bread-crumbs a,
.bread-crumbs span {
  font-size: 18px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

label {
  margin-bottom: 5px;
  font-weight: 500;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
  background-color: #00c58f;
}

.max-width-sm-padding {
  width: 33%;
}

.flex-holder-between {
  justify-content: space-between;
  min-width: 991px;
}

.btn:disabled {
  background-color: grey !important;
  border-color: grey !important;
  opacity: 0.4 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.dzu-dropzone {
  overflow: auto !important;
  max-height: 250px;
  min-height: 50px !important;
}

.dzu-dropzone .dzu-previewContainer {
  padding: 5px 3% !important;
  min-height: unset !important;
}

.radio-margin {
  margin-left: 20px;
}

.table-select-list-width {
  width: 250px;
}

.table-checkbox {
  margin-left: 44%;
}

.column-checkbox {
  width: 150px;
  text-align: center !important;
}

.tabList button.Mui-selected {
  color: #00c58f;
}

.tabList span.MuiTabs-indicator {
  background-color: #00c58f;
}

.create-act-buttons button {
  margin-right: 15px;
}

.locked-icon {
  color: #00c58f;
  background-color: transparent;
  border: none;
  margin-right: 5px;
  margin-left: 5px;
}

.locked-icon-by-myself {
  color: rgb(173, 173, 173);
  background-color: transparent;
  border: none;
  margin-right: 5px;
  margin-left: 5px;
}

.info-about-cancel-span {
  font-size: 12px;
  color: #757575;
  font-style: italic;
  margin-left: 5px;
}

.info-about-locked-acts {
  font-size: 12px;
  color: #757575;
  font-style: italic;
}

.title-span {
  margin-right: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
}

.inline-element {
  display: inline-block;
  margin-right: 10px;
}

.width-50 {
  width: calc(50% - 10px);
}

.width-25 {
  width: calc(25% - 10px);
}

.react-datepicker-popper {
  z-index: 12 !important;
}

.checkboxes-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Allows labels to wrap to the next line on small screens */
}

.react-datepicker__close-icon::after {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #0ca275 !important;
  font-size: 25px !important;
  padding-bottom: 7px !important;
}

.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--selected {
  background-color: #00c58f !important;
}

.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__year-text--selected:hover {
  background-color: #0ca275 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #00c58f !important;
  border-color: #00c58f !important;
}


.required-asterisk {
  color: red;
  margin-left: 5px;
}

.hide-modal-scroll .modal-body-scroll {
  overflow: hidden !important;
}

.datepicker-select {
  display: block;
  width: auto;
  font-size: 0.8rem;
  line-height: 1;
}

.rct-icon-half-check {
  opacity: .4;
}

.rct-icon {
  color: #00c58f;
}

.rct-title {
  font-weight: 400;
}

.center-info {
  text-align: center;
  font-style: italic;
}
.deleteButton {
    color: red;
    font-weight: bold;
    background-color: transparent;
    border: none;
}


#suggestions {
  border: 1px solid #ccc;
  max-height: 100px;
  overflow-y: auto;
  display: none;
  position: absolute;
  background: white;
}

.suggestionOption:hover {
    background-color: #e9f5f2;
}

.suggestions {
  border: 1px solid #ced4da;
  border-radius: .375rem;
  max-height: 120px;
  overflow-y: auto;
  position: absolute;
  background: white;
  width: 100%;
}