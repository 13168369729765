.HTMLContainer {
    width: 100%;
    min-height: 300px;
    border: solid 2px #eee;
    border-radius: 10px;
    overflow: auto;
    outline: none;
    font-size: 13px;
    resize: inherit;
  }

  .HTMLContainerLite {
    width: 100%;
    min-height: auto;
    height: 100%;
    border: solid 2px #eee;
    border-radius: 10px;
    overflow: auto;
    outline: none;
    font-size: 13px;
    resize: inherit;
  }

  .searcReplaceBox {
    padding: 5px;
    margin-right: 17px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:0 16px 16px -10px rgba(34,47,62,.15), 0 0 40px 1px rgba(34,47,62,.15);
    width: 20%;
    z-index: 1;
    position: absolute;
    right: 0;
  }

  .textareaContainer {
    height: auto;
    min-height: auto;
    overflow-y : auto;
    resize: none;
  }

  

  mark {
    background-color: rgb(255, 230, 0);
  }

  .closebtn:hover {
    border: none ;
  }
  
  .btnTiny {
    font-size: 15px;
    margin-bottom: 0;
    color: #fff;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 6px;
}

.btnTiny:disabled {
  background-color: grey !important;
  border-color: grey !important;
  opacity: 0.4 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.classesContainer {
  height: 500px;
  overflow-y: scroll;
  border: solid 1px #eee;
  resize: vertical;
  margin-left: -10px;
}

.itemList {
  position: static !important;
  margin: 0px !important;
  padding: 0px !important;
  text-align: left !important;
  text-indent:0px !important;
  float: none !important;
  font-family: Arial, Helvetica, sans-serif !important;
  height: auto !important;
  width: auto !important;
}

.divList{
  padding: 5px;
}

.divList:hover {
  background-color:  #CDE1FA;
}

.yellow{
  background-color: rgb(255, 230, 0); 
}

.blue{
  background-color: rgb(224, 224, 226);
}

.flex-text-editor {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - 60px);
}

.tox.tox-tinymce {
  max-height: calc(100vh - 100px);
}

.overflow-auto {
  overflow: auto;
}