.inline-block {
    color: #00c58f;
    display: inline-block;
    padding: 5px 10px;
    background: white;
    position: relative;
    cursor: pointer;
}

.collapse-hr {
    position: absolute;
    margin: 0;
    top: 50%;
    width: 97%;
    border-top: 1px solid #8e9091;
}

.collapse-title {
    position: relative;
    background-color: white;
    padding-right: 10px;
    cursor: pointer;
}

.icon-div {
    float: right;
    position: relative;
    background-color: white;
}

.children-div {
    border: 1px solid #ced4da;
    padding: 10px;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}