
.tree-node-div {
    margin-bottom: 10px;
    cursor: pointer;
    width: fit-content;
    display: inline-block;
  }
  .tree-node-div:hover {
    color: #007a59;
  }

  .selected-data {
    color: #00c58f;
  }
  .selected-data:hover {
    color: #007a59;
  }

