/* body dodao Veljo (24.12.2010) iz razloga Å¡to verovatno postoji masa dokumenata kod kojih se meÅ¡aju tipovi fontova, pa i u okviru jednog pasusa, a nemaju definisanu klasu*/

/* html { font-size: 100%; width: 100%; padding-right: 17px;} */
/* body { font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 0.75rem; width: 100%}  */

/* p {	font-family: Verdana, Arial, sans-serif; font-size: 0.75rem; line-height: 0.875rem; margin-bottom: 0;  white-space: normal; text-indent: 32px; } */
/* p * {   font-family: Verdana, Arial, sans-serif; font-size: 0.75rem; line-height: 0.875rem; margin-bottom: 0; white-space: normal;} */
/* .resize-type-em p {	font-family: Verdana, Arial, sans-serif; font-size: 0.833em; line-height: 1.4!important; margin-bottom: 0; white-space: normal; text-indent: 32px; } */
/*	line-height: 1.7; <-- променио Борис (16.XII 2010.), јер нема мерне јединице (претпостављам да је "em", што је лоше јер је мерна јединица за остале елемент "px" */



.odluka-zakon{
    font-weight: bold;
}
/* Zakonodavni deo */
.naslov,
.ukaz,
.ukaz-naslov,
.broj { font-weight: bold; text-align: center; }
.odluka-zakon { margin:15px 0 15px 0;}
.clan { text-align: center; font-weight: normal; margin:22px 0 8px 0;}/*REGISTAR 3.2.2017-izmenili zbog margina za Registar*/
.potpis { text-align: right; }

/*  Убачено (18.II 2013.) због проблема са приказивањем групних аката    */
.broj-grupa { font-weight: bold; text-align: center; }
.kraj-grupa { text-align: right; }
/*   ---   */

/* table { border:0 none; width: 100%; border-collapse: collapse; }
td,tr,th { border:0 none; }
td { padding: 3px; } */

/* Oglasni deo */
.firma { text-align: center; }
.resenje { text-align: center; }
td p { text-indent: 0px; }
/* .bold { font-weight: bold; } */



/* preuzeto iz genrep-layout.css-a */
/* body { background-color: #d3dbe6; text-align: center; margin:0 auto; max-width: 960px; }*/
/*body.intro { background-color: #d3dbe6; text-align: center; margin:0 auto; max-width: 1200px; }*/
/*body.registar { background: url(../images/register_header_bg.jpg) repeat-x top left #d3dbe6; margin-left: 40px; max-width: 1200px; }*/
/*body.arhiva { background: url("../images/arhiva-body-bg.jpg") repeat-x left top #d3dbe6; text-align: center; margin:0 auto; width: 100%; }*/

.STILOVI_MML {text-align: center;font-size: 16pt;text-transform: uppercase;color: #0e9606;}/*novo kreirano 3.2.2017 samo informatinog karaktera*/

.akt {text-indent:2.4rem; text-align:justify;}
.aktSUPA-MML {text-indent:2.4rem; text-align:justify; margin: 0 0 0 0; line-height:1rem;display: block;}/*MML 17.08.2016-izmenio Nikola zbog margina 08.09.2016*/
.naslov_MML {font-size: 0.92rem;  font-weight: bold; text-align: center; margin: 0 0 0 0;  line-height:1.2rem;}/*MML 3.2.2017 ukinuta margina*/
.bold_MML { font-weight: bold; text-align: center;  margin: 0 0 0 0; }/*novo kreirano 3.2.2017*/
.bolditalikLeft { font-weight: bold; font-style:italic; text-indent:32px; text-align:justify; margin: 0 0 0 0;}/*MML 17.08.2016-izmenio Nikola zbog margina 08.09.2016*/
.boldLeft { font-weight: bold; text-indent:32px; text-align:justify; margin: 0 0 0 0;}/*MML 17.08.2016-izmenio Nikola zbog margina 08.09.2016*/
.italik_MML { font-style: italic; text-align: center; margin: 0 0 0 0; } /*novo kreirano 3.2.2017*/
.italikLeft { font-style: italic; text-align:justify; margin: 0 0 0 0;}/*MML 17.08.2016-izmenio Nikola zbog margina 08.09.2016*/
.underline_MML {text-decoration: underline; text-align: center; }/*novo kreirano 3.2.2017*/
.underlineLeft {text-decoration: underline;}
.spacija_MML { letter-spacing: 1.8px; text-align: center;  margin: 0 0 0 0;}/*novo kreirano 3.2.2017*/
.centar_MML  {text-align: center; margin: 0 0 0 0; }/*novo kreirano 3.2.2017*/
.footnote {font-size: smaller;  font-style: italic; text-align:justify;}/*MML 17.08.2016*/

.superscript  {vertical-align: super;   font-size: smaller;}/*izmena E-redakcija 17.08.2016 { font-size: 0.5rem; position: relative; bottom: 6px; }*/
.subscript  {vertical-align: sub;   font-size: smaller;}/*izmena E-redakcija 17.08.2016  { font-size: 0.5rem; position: relative; top: 4px; }*/

.tabela-podnaslov {	border: 1px solid #808080;	text-align: center;	margin: 0 0 0 0;	background-color: #D8D8D8; 	text-transform: uppercase;font-family: Verdana!important;}

.tabela-izvor {text-indent:32px; border: 1px solid #808080;	background-color: #D8D8D8; font-style:italic;font-family: Verdana!important; padding: 20px 0 20px 0;}
/* .tabela-izvor td {} */
.tabela-izvor td p { text-indent:32px; font-size:100%; font-family: Verdana!important;} 

.tabela-citat {text-indent:32px!important; border: 1px solid #808080;	background-color: #f5f5f5; font-size:90%; font-style:italic; line-height: 100%;font-family: Verdana!important;}
/* .tabela-citat td {} */
.tabela-citat td p { text-indent:32px; font-size:100%; font-family: Verdana!important;}


.tabela-sa-borderom-MML { border: 1px solid #808080; border-collapse: collapse; background-color: #f5f5f5; width:100%; font-family: Verdana!important; font-size: 0.75rem; line-height: 0.875rem; margin-bottom: 0;text-indent:32px; font-style:italic;}
/* .tabela-sa-borderom-MML td { border: 1px solid #808080; padding: 1px 1px 1px 3px; background-color: #f5f50f5; font-family: Verdana!important; font-size: 0.75rem; line-height: 0.875rem; margin-bottom: 0;text-indent:32px;font-style:italic;} */
.tabela-sa-borderom-MML td p { text-indent:32px; text-align:justify; font-family: Verdana!important; font-size: 0.75rem; line-height: 0.875rem; margin-bottom: 0;font-style:italic;}

.tabela-autor {	border: 1px solid #808080;	text-align: right; font-style:italic;	margin: 0 0 0 0;	background-color: #f5f5f5; padding-top:20px ;padding-bottom:20px;font-family: Verdana!important;}


.STILOVI_SUDSKA_PRAKSA {text-align: center;font-size: 16pt;text-transform: uppercase;}/*novo kreirano 3.2.2017 samo informatinog karaktera*/

.sentenca_naslov {font-weight: bold; margin-top:18px; margin-bottom:18px; text-align:center;}
.izvod_iz_presude { font-weight: bold; text-align:center; margin: 0 0 0 0; } /*Dodao Nikola zbog margina 08.09.2016-SUPA*/

.sentenca {	border: 1px solid #000;	border-collapse: collapse;	text-indent: 2.4rem;	font-weight: bold;}
.sentenca td { border: 1px solid #000; padding: 1px 1px 1px 3px; text-indent:32px;; margin-top:32px;}
.sentenca td p {   font-weight: bold; text-indent:32px; text-align:justify; }

.tabela-izvod {border: 1px solid #000; text-indent:32px!important;}/*dodao Nikola zbog margina 08.09.2016-SUPA*/


/*STILOVI ZA PREČIŠĆAVANJE */

.STILOVI_REGISTAR {text-align: center;font-size: 16pt;text-transform: uppercase;}/*novo kreirano 3.2.2017 samo informatinog karaktera*/
.bold { font-weight: bold; text-align: center; margin:22px 0 8px 0;}/*REGISTAR 3.2.2017-izmenili zbog margina za Registar*/
.italik { font-style: italic; text-align: center; margin:22px 0 8px 0;} /*REGISTAR 3.2.2017-izmenili zbog margina za Registar*/
.underline {text-decoration: underline; text-align: center;  margin:22px 0 8px 0;}/*REGISTAR 3.2.2017-izmenili zbog margina za Registar*/
.spacija { letter-spacing: 1.8px; text-align: center;margin:22px 0 8px 0; } /*REGISTAR 3.2.2017-izmenili zbog margina za Registar*/
.centar  {text-align: center; margin:15px 0 8px 0; }/*REGISTAR 3.2.2017-izmenili zbog margina za Registar*/
.potpis {text-align: right; }

.tabela-sa-borderom { border: 1px solid #000; border-collapse: collapse; }
.tabela-sa-borderom td { border: 1px solid #000; padding: 1px 1px 1px 3px; }
.tabela-sa-borderom td p { text-indent:0px;  }

.tabela-bez-bordera{ border: none; }

.redakcijskipreciscentekst{ border: medium thin Black; text-align: right; font-weight: bold; border-style: solid; position: absolute; right: 5px; top: 5px; padding:2px 5px; border-width: 1px; margin-right: 5px; margin-top: 5px;text-indent:0px!important; }
	
.nevazeciakt{ border: medium thin Black; text-align: right; margin-top:40px; font-weight: bold; border-style: solid; position: absolute; right: 5px; top: 5px; padding:2px 5px; border-width: 1px; margin-right: 5px; color:red; text-indent:0px!important; }

/*ZVEZDICE ZA PREČIŠĆAVANJE */



.v2-clan-left-1 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-1 {
	font-weight: normal;
}
.state-changes .v2-clan-left-1 * {
	font-weight: normal;
}
.v2-clan-left-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-clan-left-1:after {
	content: none;
}
.v2-clan-left-2 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-2 {
	font-weight: normal;
}
.state-changes .v2-clan-left-2 * {
	font-weight: normal;
}
.v2-clan-left-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-clan-left-2:after {
	content: none;
}
.v2-clan-left-3 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-3 {
	font-weight: normal;
}
.state-changes .v2-clan-left-3 * {
	font-weight: normal;
}
.v2-clan-left-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-clan-left-3:after {
	content: none;
}
.v2-clan-left-4 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-4 {
	font-weight: normal;
}
.state-changes .v2-clan-left-4 * {
	font-weight: normal;
}
.v2-clan-left-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-clan-left-4:after {
	content: none;
}
.v2-clan-left-5 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-5 {
	font-weight: normal;
}
.state-changes .v2-clan-left-5 * {
	font-weight: normal;
}
.v2-clan-left-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-clan-left-5:after {
	content: none;
}
.v2-clan-left-6 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-6 {
	font-weight: normal;
}
.state-changes .v2-clan-left-6 * {
	font-weight: normal;
}
.v2-clan-left-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-clan-left-6:after {
	content: none;
}
.v2-clan-left-7 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-7 {
	font-weight: normal;
}
.state-changes .v2-clan-left-7 * {
	font-weight: normal;
}
.v2-clan-left-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-clan-left-7:after {
	content: none;
}
.v2-clan-left-8 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-8 {
	font-weight: normal;
}
.state-changes .v2-clan-left-8 * {
	font-weight: normal;
}
.v2-clan-left-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-clan-left-8:after {
	content: none;
}
.v2-clan-left-9 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-9 {
	font-weight: normal;
}
.state-changes .v2-clan-left-9 * {
	font-weight: normal;
}
.v2-clan-left-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-clan-left-9:after {
	content: none;
}
.v2-clan-left-10 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-10 {
	font-weight: normal;
}
.state-changes .v2-clan-left-10 * {
	font-weight: normal;
}
.v2-clan-left-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-clan-left-10:after {
	content: none;
}

.v2-underline-left {
	/* font-decoration: underline; */
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-underline-left {
	font-weight: normal;
}
.state-changes .v2-underline-left * {
	font-weight: normal;
}
.v2-spacija-left-1 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-1 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-1 * {
	font-weight: normal;
}
.v2-spacija-left-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-spacija-left-1:after {
	content: none;
}
.v2-spacija-left-2 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-2 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-2 * {
	font-weight: normal;
}
.v2-spacija-left-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-spacija-left-2:after {
	content: none;
}
.v2-spacija-left-3 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-3 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-3 * {
	font-weight: normal;
}
.v2-spacija-left-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-spacija-left-3:after {
	content: none;
}


.v2-italik-left-1 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-1 {
	font-weight: normal;
}
.state-changes .v2-italik-left-1 * {
	font-weight: normal;
}
.v2-italik-left-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-italik-left-1:after {
	content: none;
}
.v2-italik-left-2 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-2 {
	font-weight: normal;
}
.state-changes .v2-italik-left-2 * {
	font-weight: normal;
}
.v2-italik-left-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-italik-left-2:after {
	content: none;
}
.v2-italik-left-3 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-3 {
	font-weight: normal;
}
.state-changes .v2-italik-left-3 * {
	font-weight: normal;
}
.v2-italik-left-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-italik-left-3:after {
	content: none;
}



.v2-bold-left-1 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-1 {
	text-decoration: none;
}
.state-changes .v2-bold-left-1 * {
	text-decoration: none;
}
.v2-bold-left-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-bold-left-1:after {
	content: none;
}
.v2-bold-left-2 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-2 {
	text-decoration: none;
}
.state-changes .v2-bold-left-2 * {
	text-decoration: none;
}
.v2-bold-left-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-bold-left-2:after {
	content: none;
}
.v2-bold-left-3 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-3 {
	text-decoration: none;
}
.state-changes .v2-bold-left-3 * {
	text-decoration: none;
}
.v2-bold-left-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-bold-left-3:after {
	content: none;
}


.v2-clan-1 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-1 {
	font-weight: normal;
}
.state-changes .v2-clan-1 * {
	font-weight: normal;
}
.v2-clan-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-clan-1:after {
	content: none;
}
.v2-clan-2 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-2 {
	font-weight: normal;
}
.state-changes .v2-clan-2 * {
	font-weight: normal;
}
.v2-clan-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-clan-2:after {
	content: none;
}
.v2-clan-3 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-3 {
	font-weight: normal;
}
.state-changes .v2-clan-3 * {
	font-weight: normal;
}
.v2-clan-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-clan-3:after {
	content: none;
}




.v2-underline {
	/* font-decoration: underline; */
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-underline {
	font-weight: normal;
}
.state-changes .v2-underline * {
	font-weight: normal;
}









.v2-spacija-1 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-1 {
	font-weight: normal;
}
.state-changes .v2-spacija-1 * {
	font-weight: normal;
}
.v2-spacija-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-spacija-1:after {
	content: none;
}
.v2-spacija-2 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-2 {
	font-weight: normal;
}
.state-changes .v2-spacija-2 * {
	font-weight: normal;
}
.v2-spacija-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-spacija-2:after {
	content: none;
}
.v2-spacija-3 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-3 {
	font-weight: normal;
}
.state-changes .v2-spacija-3 * {
	font-weight: normal;
}
.v2-spacija-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-spacija-3:after {
	content: none;
}





.v2-italik-1 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-1 {
	font-weight: normal;
}
.state-changes .v2-italik-1 * {
	font-weight: normal;
}
.v2-italik-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-italik-1:after {
	content: none;
}
.v2-italik-2 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-2 {
	font-weight: normal;
}
.state-changes .v2-italik-2 * {
	font-weight: normal;
}
.v2-italik-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-italik-2:after {
	content: none;
}
.v2-italik-3 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-3 {
	font-weight: normal;
}
.state-changes .v2-italik-3 * {
	font-weight: normal;
}
.v2-italik-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-italik-3:after {
	content: none;
}





.v2-bold-1 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-1 {
	text-decoration: none;
}
.state-changes .v2-bold-1 * {
	text-decoration: none;
}
.v2-bold-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-bold-1:after {
	content: none;
}
.v2-bold-2 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-2 {
	text-decoration: none;
}
.state-changes .v2-bold-2 * {
	text-decoration: none;
}
.v2-bold-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-bold-2:after {
	content: none;
}
.v2-bold-3 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-3 {
	text-decoration: none;
}
.state-changes .v2-bold-3 * {
	text-decoration: none;
}
.v2-bold-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-bold-3:after {
	content: none;
}



.v2-clan-verzal-1 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-1 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-1 * {
	font-weight: normal;
}
.v2-clan-verzal-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-clan-verzal-1:after {
	content: none;
}
.v2-clan-verzal-2 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-2 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-2 * {
	font-weight: normal;
}
.v2-clan-verzal-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-clan-verzal-2:after {
	content: none;
}



.v2-italik-verzal-1 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-1 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-1 * {
	font-weight: normal;
}
.v2-italik-verzal-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-italik-verzal-1:after {
	content: none;
}
.v2-italik-verzal-2 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-2 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-2 * {
	font-weight: normal;
}
.v2-italik-verzal-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-italik-verzal-2:after {
	content: none;
}
.v2-italik-verzal-3 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-3 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-3 * {
	font-weight: normal;
}
.v2-italik-verzal-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-italik-verzal-3:after {
	content: none;
}




.v2-bold-verzal-1 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-1 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-1 * {
	text-decoration: none;
}
.v2-bold-verzal-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-bold-verzal-1:after {
	content: none;
}
.v2-bold-verzal-2 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-2 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-2 * {
	text-decoration: none;
}
.v2-bold-verzal-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-bold-verzal-2:after {
	content: none;
}
.v2-bold-verzal-3 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-3 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-3 * {
	text-decoration: none;
}
.v2-bold-verzal-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-bold-verzal-3:after {
	content: none;
}


.v2-naslov-1 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-1 {
	text-decoration: none;
}
.state-changes .v2-naslov-1 * {
	text-decoration: none;
}
.v2-naslov-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-naslov-1:after {
	content: none;
}
.v2-naslov-2 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-2 {
	text-decoration: none;
}
.state-changes .v2-naslov-2 * {
	text-decoration: none;
}
.v2-naslov-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-naslov-2:after {
	content: none;
}
.v2-naslov-3 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-3 {
	text-decoration: none;
}
.state-changes .v2-naslov-3 * {
	text-decoration: none;
}
.v2-naslov-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-naslov-3:after {
	content: none;
}




.v2-odluka-zakon-1 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-1 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-1 * {
	text-decoration: none;
}
.v2-odluka-zakon-1:after {
	content: '*';
	display: inline;
}
.state-changes .v2-odluka-zakon-1:after {
	content: none;
}
.v2-odluka-zakon-2 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-2 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-2 * {
	text-decoration: none;
}
.v2-odluka-zakon-2:after {
	content: '**';
	display: inline;
}
.state-changes .v2-odluka-zakon-2:after {
	content: none;
}
.v2-odluka-zakon-3 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-3 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-3 * {
	text-decoration: none;
}
.v2-odluka-zakon-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-odluka-zakon-3:after {
	content: none;
}

/*KRAJ ZVEZDICA*/



.pronadjen { background-color: yellow;}



/*.broj, .clan p  { text-align: center; text-indent: 0px; }*/
/*.broj, .clan  { text-align: center; }*/

/* .story { height: 100%; } - промењено према Ралетовом упутству (емаЈл 20180301_0953) */

.story p { white-space: normal; }
.story p.f { text-indent:0; font-style:italic; position:relative; bottom:4px; }



/* овај део је додат 20180302 према Ралетовом упутству (Проинтеров OpenProject #41) */
/* проширен 20180725 према Ралетовом упутству (Проинтеров OpenProject #90) због слика ротираних преко EXIF-метаподатака */
.story * > img {
	max-width: calc(100% - 50px);
	max-width: -webkit-calc(100% - 50px);
	max-width: -moz-calc(100% - 50px);
	overflow-y: hidden;
	image-orientation: from-image;
}



.eksponent {
        font-size: .83rem;
        vertical-align: super;
        color: #66c;
        font-weight: bold;
        }
.strongEksponent {
        vertical-align: super;
        color: #66f;
        font-weight: bold;
        }
.extraStrongEksponent {
        font-size: 1.17rem;
        vertical-align: super;
        color: #99f;
        font-weight: bold;
        }
.indekas {
        font-size: .83rem;
        vertical-align: sub;
        color: #6c6;
        font-style: italic;
        }
.strongIndekas {

	font-weight: normal!important;
        vertical-align: sub;
        color: #6f6;
        font-style: italic;
        }
.extraStrongIndekas {
        font-size: 1.17rem;
        vertical-align: sub;
        color: #9f9;
        font-style: italic;
        }
.subareaOutputPanel {
    font-family: Arial,Verdana,sans-serif;
}

.sadrzajApstrakt {
    color: #484848;
    font-family: Arial,Helvetica,sans-serif;
    padding: 2px 0px;
    font-style: normal;
    text-align: left;
    font-size: 0.625rem;
}
.sadrzajApstrakt1 {
	color:#484848;
	font-family:Arial,Helvetica,sans-serif;
	padding:2px 0 2px 28px;
	font-style:italic;
	text-align:left;
	font-size:0.6875rem;
}
.sadrzajApstrakt2 {
    color: #484848;
    font-family: Arial,Helvetica,sans-serif;
    padding: 2px 0px 2px 48px;
    font-style: italic;
    text-align: left;
    font-size: 0.6875rem;
}

/*div.lawDisplay p { text-indent: 23px; }*/
.potpis { text-align: right; }
/**/


.rich-mp-content-table {
/* cellspacing:0px ; */
/* cellpadding:0px; */
 border:0px;
 height: 100%;
 width: 100%;
}
.rich-mpnl-header-cell{
	height: 30px; 
	font-family: Arial,Verdana,sans-serif; 
	font-size: 12; 
	text-align: left;
	background-repeat: repeat-x;
	/* background-image: url("../pagedata/blueBackMenuBar.jpg"); */
	color: White
}
.rich-mpnl-text {
    color: #000;
    font-family: Arial,Verdana,sans-serif;
    font-size: 13px;
    font-weight: bold;
}
.rich-mpnl-header {
    height: 30px;
    line-height: 30px;
    font-family: Arial,Verdana,sans-serif;
    text-align: left;
    background-position: left top;
    background-repeat: repeat-x;
    background-color: green;
    color: #FFF;
    border-style: none;
    padding: 2px 2px 2px 6px;
    vertical-align: middle;
}
.rich-mpnl-body {
	padding:3px;
	background-color: #FFFFFF;
}
.noborder {
    border-style: none;
    width: 100%;
    height: 100%;
    vertical-align: top;
}
.searchbar {
    padding: 0;
    text-align: right;
    vertical-align: middle;
	height: 20px;
    width: 100%;
}
.searchbar .links {
    display: inline;
    float: left;
    position: relative;
    width: 960px;
}
.searchbar .links .regLinks {
    display: inline;
    float: left;
    position: relative;
    width: 127px;
    line-height: 12px;
    color: #028002;
    text-align: center;
    text-decoration: none;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E5E5E5;
    background-color: #E9E9E9;
    background-image: linear-gradient(to bottom, #E9E9E9 0%, #D6D6D6 100%);
}
.rich-table-row {
    color: Blue;
}
.priloziTabColumn {
    border-style: none;
    color: #000;
    font-family: Arial,Verdana,sans-serif;
    font-size: 11px;
    overflow: hidden;
    padding: 4px;
}
.rich-table-cell {
    border-bottom: medium none;
    border-right: medium none;
}
.searchbar .links .tab01MI {
    padding: 3px 20px;
    width: 97px;
}
.searchbar .links .tab02PI {
    padding: 8px 0 10px;
}
.searchbar .links .tab03MO {
    padding: 3px 0;
}
.searchbar .links .tab04LI {
    padding: 8px 0 10px;
}
.searchbar .links .tab05SU {
    padding: 8px 0 10px;
}
.mediaContent {
    width: 800px;
    height: 100%;
    vertical-align: top;
    border: medium none;
}
.priloziContent {
    width: 150px;
    height: 100%;
    vertical-align: top;
    overflow-x: hidden;
    overflow-y: auto;
}
.priloziContent div {
    overflow: auto;
    height: 600px;
}
.priloziTab {
    background-color: #FFF;
    border-style: none none solid;
    color: #000;
    font-family: Arial,Verdana,sans-serif;
    font-size: 11px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #C0C0C0;
}
.sadrzajNaslov {
    border-bottom: 1px solid #C9CBCD;
    margin: 0px 0px 10px 10px;
    padding: 20px 1px 5px 5px;
    text-align: left;
    color: #1C9500;
    font-family: Arial,sans-serif;
    font-size: 20px;
    font-weight: normal;
}
.sadrzajLista {
	margin-left: 0;
}
.rich-panel-body {
    padding: 0;
}
.rich-panel {
	border-style: none;
	background-color: white;
}
.rich-page {
	width:960px;
}
.sadrzajRubrikeMenuStil {
    background-color: white;
    vertical-align: top;
}
.sadrzajRubrikeMenuStilDiv {
    background-color: white;
}
.groupPrint {
    float: right;
    position: relative;
    right: 4px;
    top: 2px;
}
.displayNone {
    display: none;
    margin: 0;
}
.sadrzajTable {
    font-size: 12px;
    width: 100%;
}
.sadrzajNadNaslov {
    color: #484848;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-style: italic;
    padding: 2px 0;
    text-align: left;
}
.sadrzajNadNaslov1 {
    color: #484848;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-style: italic;
    padding: 2px 0;
    text-align: left;
}
.sadrzajNadNaslov2 {
    color: #000;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
    font-style: normal;
    padding: 2px 0 2px 32px;
    text-align: left;
}
.sadrzajPodNaslov {
    color: #484848;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-style: italic;
    padding: 2px 0;
    text-align: left;
}
.sadrzajPodNaslov1 {
    color: #484848;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-style: italic;
    padding: 2px 0;
    text-align: left;
}
.sadrzajPodNaslov2 {
    color: #484848;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 11px;
    padding: 2px 0 2px 20px;
    text-align: left;
}
.sadrzajDiv {
    background: #ffffff none repeat scroll 0 0;
    padding: 5px;
    text-align: left;
}
.sadrzajDiv1 {
    background: #f4f6f9 none repeat scroll 0 0;
    padding: 5px;
    text-align: left;
}
.sadrzajDiv2 {
    background: #ffffff none repeat scroll 0 0;
    padding: 5px;
    text-align: left;
}
.sadrzajHier {
    padding-top: 4px;
    vertical-align: top;
    width: 40px;
}
.sadrzajHier1 {
    padding-top: 4px;
    vertical-align: top;
    width: 4%;
}
.sadrzajHier2 {
    padding-left: 20px;
    padding-top: 4px;
    vertical-align: top;
    width: 4%;
}
.sadrzajSubarea {
    background: #f4f6f9 none repeat scroll 0 0;
    color: #000000;
    font-family: Arial,sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}
.sadrzajLink {
    color: #007000;
    cursor: pointer;
    font-family: Arial,sans-serif;
    font-size: 12px;
    font-weight: bold;
    padding: 0;
    text-decoration: underline;
}
.sadrzajMiddle {
    font-weight: normal;
}
.sadrzajMiddle1 {
    font-weight: bold;
	font-family: Arial,Verdana,sans-serif;
}
.sadrzajMiddle2 {
    font-weight: normal;
	font-family: Arial,Verdana,sans-serif;
}
.sadrzajRight {
    font-weight: normal;
}
.sadrzajRight1 {
    font-weight: normal;
    font-style: italic;
	font-family: Arial,Verdana,sans-serif;
}
.sadrzajRight2 {
    font-weight: normal;
    font-style: italic;
	font-family: Arial,Verdana,sans-serif;
}
.rich-mpnl-shadow {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 4px;
    left: 4px;
    opacity: 0.1;
}
.rich-mpnl-content {
    border-width: 1px;
    border-style: solid;
    padding: 1px;
    background-color: inherit;
}
.rich-mpnl-trim {
    position: relative;
    z-index: 0;
}
.rich-mpnl-ovf-hd {
    overflow: hidden;
}
.rich-table {
    border-top: 1px solid #C0C0C0;
    border-left: 1px solid #C0C0C0;
    background-color: #FFF;
    empty-cells: show;
    border-collapse: collapse;
}
.rich-table-thead {
    border-bottom: 1px solid #C0C0C0;
}
.rich-table-header {
    background-color: #005000;
    background-position: left top;
    background-repeat: repeat-x;
}
.rich-table-headercell {
    border-right: 1px solid #C0C0C0;
    border-bottom: 1px solid #C0C0C0;
    padding: 4px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    font-size: 11px;
    font-family: Arial,Verdana,sans-serif;
}
.priloziTabHeader {
    background-color: #EEE;
    border-style: none;
    color: #000;
    font-family: Arial,Verdana,sans-serif;
    font-size: 11px;
    font-weight: bold;
    padding: 4px 4px 4px 8px;
    text-align: left;
    letter-spacing: 1px;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: default;
}
.priloziLink{
	color: #000000;
	text-decoration: none
}



.sadrzajLink {
	color: #007000;
	cursor: pointer;
	font-family: Arial,sans-serif;
	font-size: 12px;
	font-weight: bold;
	padding: 0;
	text-decoration: underline;
	}
.sadrzajRight3 {
	font-weight: normal;
	font-style: italic;
	}
.sadrzajHier3 {
	vertical-align: top;
	width: 4%;
	padding-top: 4px;
	padding-left: 42px;
	}
.sadrzajApstrakt3 {
	color: #484848;
	font-family: Arial,Helvetica,sans-serif;
	padding: 2px 0px 2px 82px;
	font-style: italic;
	text-align: left;
	font-size: 11px;
	}


/*
	Pregled akata sa/bez posebno istaknutim napomenama 
*/

/*
	Generisano START
*/

.v2-odluka-zakon-4 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-4 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-4 * {
	text-decoration: none;
}
.v2-odluka-zakon-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-odluka-zakon-4:after {
	content: none;
}
.v2-odluka-zakon-5 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-5 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-5 * {
	text-decoration: none;
}
.v2-odluka-zakon-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-odluka-zakon-5:after {
	content: none;
}
.v2-odluka-zakon-6 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-6 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-6 * {
	text-decoration: none;
}
.v2-odluka-zakon-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-odluka-zakon-6:after {
	content: none;
}
.v2-odluka-zakon-7 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-7 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-7 * {
	text-decoration: none;
}
.v2-odluka-zakon-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-odluka-zakon-7:after {
	content: none;
}
.v2-odluka-zakon-8 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-8 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-8 * {
	text-decoration: none;
}
.v2-odluka-zakon-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-odluka-zakon-8:after {
	content: none;
}
.v2-odluka-zakon-9 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-9 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-9 * {
	text-decoration: none;
}
.v2-odluka-zakon-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-odluka-zakon-9:after {
	content: none;
}
.v2-odluka-zakon-10 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-10 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-10 * {
	text-decoration: none;
}
.v2-odluka-zakon-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-odluka-zakon-10:after {
	content: none;
}
.v2-odluka-zakon-11 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-11 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-11 * {
	text-decoration: none;
}
.v2-odluka-zakon-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-odluka-zakon-11:after {
	content: none;
}
.v2-odluka-zakon-12 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-12 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-12 * {
	text-decoration: none;
}
.v2-odluka-zakon-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-odluka-zakon-12:after {
	content: none;
}
.v2-odluka-zakon-13 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-13 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-13 * {
	text-decoration: none;
}
.v2-odluka-zakon-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-odluka-zakon-13:after {
	content: none;
}
.v2-odluka-zakon-14 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-14 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-14 * {
	text-decoration: none;
}
.v2-odluka-zakon-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-odluka-zakon-14:after {
	content: none;
}
.v2-odluka-zakon-15 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-15 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-15 * {
	text-decoration: none;
}
.v2-odluka-zakon-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-odluka-zakon-15:after {
	content: none;
}
.v2-odluka-zakon-16 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-16 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-16 * {
	text-decoration: none;
}
.v2-odluka-zakon-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-odluka-zakon-16:after {
	content: none;
}
.v2-odluka-zakon-17 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-17 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-17 * {
	text-decoration: none;
}
.v2-odluka-zakon-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-odluka-zakon-17:after {
	content: none;
}
.v2-odluka-zakon-18 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-18 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-18 * {
	text-decoration: none;
}
.v2-odluka-zakon-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-odluka-zakon-18:after {
	content: none;
}
.v2-odluka-zakon-19 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-19 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-19 * {
	text-decoration: none;
}
.v2-odluka-zakon-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-odluka-zakon-19:after {
	content: none;
}
.v2-odluka-zakon-20 {
	font-weight: bold;
	text-align: center;	
margin:24px 0 10px 0;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-odluka-zakon-20 {
	text-decoration: none;
}
.state-changes .v2-odluka-zakon-20 * {
	text-decoration: none;
}
.v2-odluka-zakon-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-odluka-zakon-20:after {
	content: none;
}
.v2-naslov-4 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-4 {
	text-decoration: none;
}
.state-changes .v2-naslov-4 * {
	text-decoration: none;
}
.v2-naslov-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-naslov-4:after {
	content: none;
}
.v2-naslov-5 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-5 {
	text-decoration: none;
}
.state-changes .v2-naslov-5 * {
	text-decoration: none;
}
.v2-naslov-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-naslov-5:after {
	content: none;
}
.v2-naslov-6 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-6 {
	text-decoration: none;
}
.state-changes .v2-naslov-6 * {
	text-decoration: none;
}
.v2-naslov-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-naslov-6:after {
	content: none;
}
.v2-naslov-7 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-7 {
	text-decoration: none;
}
.state-changes .v2-naslov-7 * {
	text-decoration: none;
}
.v2-naslov-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-naslov-7:after {
	content: none;
}
.v2-naslov-8 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-8 {
	text-decoration: none;
}
.state-changes .v2-naslov-8 * {
	text-decoration: none;
}
.v2-naslov-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-naslov-8:after {
	content: none;
}
.v2-naslov-9 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-9 {
	text-decoration: none;
}
.state-changes .v2-naslov-9 * {
	text-decoration: none;
}
.v2-naslov-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-naslov-9:after {
	content: none;
}
.v2-naslov-10 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-10 {
	text-decoration: none;
}
.state-changes .v2-naslov-10 * {
	text-decoration: none;
}
.v2-naslov-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-naslov-10:after {
	content: none;
}
.v2-naslov-11 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-11 {
	text-decoration: none;
}
.state-changes .v2-naslov-11 * {
	text-decoration: none;
}
.v2-naslov-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-naslov-11:after {
	content: none;
}
.v2-naslov-12 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-12 {
	text-decoration: none;
}
.state-changes .v2-naslov-12 * {
	text-decoration: none;
}
.v2-naslov-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-naslov-12:after {
	content: none;
}
.v2-naslov-13 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-13 {
	text-decoration: none;
}
.state-changes .v2-naslov-13 * {
	text-decoration: none;
}
.v2-naslov-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-naslov-13:after {
	content: none;
}
.v2-naslov-14 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-14 {
	text-decoration: none;
}
.state-changes .v2-naslov-14 * {
	text-decoration: none;
}
.v2-naslov-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-naslov-14:after {
	content: none;
}
.v2-naslov-15 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-15 {
	text-decoration: none;
}
.state-changes .v2-naslov-15 * {
	text-decoration: none;
}
.v2-naslov-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-naslov-15:after {
	content: none;
}
.v2-naslov-16 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-16 {
	text-decoration: none;
}
.state-changes .v2-naslov-16 * {
	text-decoration: none;
}
.v2-naslov-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-naslov-16:after {
	content: none;
}
.v2-naslov-17 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-17 {
	text-decoration: none;
}
.state-changes .v2-naslov-17 * {
	text-decoration: none;
}
.v2-naslov-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-naslov-17:after {
	content: none;
}
.v2-naslov-18 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-18 {
	text-decoration: none;
}
.state-changes .v2-naslov-18 * {
	text-decoration: none;
}
.v2-naslov-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-naslov-18:after {
	content: none;
}
.v2-naslov-19 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-19 {
	text-decoration: none;
}
.state-changes .v2-naslov-19 * {
	text-decoration: none;
}
.v2-naslov-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-naslov-19:after {
	content: none;
}
.v2-naslov-20 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-naslov-20 {
	text-decoration: none;
}
.state-changes .v2-naslov-20 * {
	text-decoration: none;
}
.v2-naslov-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-naslov-20:after {
	content: none;
}
.v2-bold-verzal-4 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-4 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-4 * {
	text-decoration: none;
}
.v2-bold-verzal-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-bold-verzal-4:after {
	content: none;
}
.v2-bold-verzal-5 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-5 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-5 * {
	text-decoration: none;
}
.v2-bold-verzal-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-bold-verzal-5:after {
	content: none;
}
.v2-bold-verzal-6 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-6 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-6 * {
	text-decoration: none;
}
.v2-bold-verzal-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-bold-verzal-6:after {
	content: none;
}
.v2-bold-verzal-7 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-7 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-7 * {
	text-decoration: none;
}
.v2-bold-verzal-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-bold-verzal-7:after {
	content: none;
}
.v2-bold-verzal-8 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-8 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-8 * {
	text-decoration: none;
}
.v2-bold-verzal-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-bold-verzal-8:after {
	content: none;
}
.v2-bold-verzal-9 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-9 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-9 * {
	text-decoration: none;
}
.v2-bold-verzal-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-bold-verzal-9:after {
	content: none;
}
.v2-bold-verzal-10 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-10 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-10 * {
	text-decoration: none;
}
.v2-bold-verzal-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-bold-verzal-10:after {
	content: none;
}
.v2-bold-verzal-11 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-11 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-11 * {
	text-decoration: none;
}
.v2-bold-verzal-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-bold-verzal-11:after {
	content: none;
}
.v2-bold-verzal-12 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-12 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-12 * {
	text-decoration: none;
}
.v2-bold-verzal-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-bold-verzal-12:after {
	content: none;
}
.v2-bold-verzal-13 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-13 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-13 * {
	text-decoration: none;
}
.v2-bold-verzal-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-bold-verzal-13:after {
	content: none;
}
.v2-bold-verzal-14 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-14 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-14 * {
	text-decoration: none;
}
.v2-bold-verzal-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-bold-verzal-14:after {
	content: none;
}
.v2-bold-verzal-15 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-15 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-15 * {
	text-decoration: none;
}
.v2-bold-verzal-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-bold-verzal-15:after {
	content: none;
}
.v2-bold-verzal-16 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-16 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-16 * {
	text-decoration: none;
}
.v2-bold-verzal-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-bold-verzal-16:after {
	content: none;
}
.v2-bold-verzal-17 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-17 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-17 * {
	text-decoration: none;
}
.v2-bold-verzal-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-bold-verzal-17:after {
	content: none;
}
.v2-bold-verzal-18 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-18 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-18 * {
	text-decoration: none;
}
.v2-bold-verzal-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-bold-verzal-18:after {
	content: none;
}
.v2-bold-verzal-19 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-19 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-19 * {
	text-decoration: none;
}
.v2-bold-verzal-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-bold-verzal-19:after {
	content: none;
}
.v2-bold-verzal-20 {
	font-weight: bold;
	text-align: center;	
text-transform: uppercase;	
text-decoration: underline;
}
.state-changes .v2-bold-verzal-20 {
	text-decoration: none;
}
.state-changes .v2-bold-verzal-20 * {
	text-decoration: none;
}
.v2-bold-verzal-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-bold-verzal-20:after {
	content: none;
}
.v2-italik-verzal-4 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-4 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-4 * {
	font-weight: normal;
}
.v2-italik-verzal-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-italik-verzal-4:after {
	content: none;
}
.v2-italik-verzal-5 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-5 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-5 * {
	font-weight: normal;
}
.v2-italik-verzal-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-italik-verzal-5:after {
	content: none;
}
.v2-italik-verzal-6 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-6 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-6 * {
	font-weight: normal;
}
.v2-italik-verzal-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-italik-verzal-6:after {
	content: none;
}
.v2-italik-verzal-7 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-7 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-7 * {
	font-weight: normal;
}
.v2-italik-verzal-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-italik-verzal-7:after {
	content: none;
}
.v2-italik-verzal-8 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-8 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-8 * {
	font-weight: normal;
}
.v2-italik-verzal-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-italik-verzal-8:after {
	content: none;
}
.v2-italik-verzal-9 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-9 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-9 * {
	font-weight: normal;
}
.v2-italik-verzal-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-italik-verzal-9:after {
	content: none;
}
.v2-italik-verzal-10 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-10 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-10 * {
	font-weight: normal;
}
.v2-italik-verzal-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-italik-verzal-10:after {
	content: none;
}
.v2-italik-verzal-11 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-11 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-11 * {
	font-weight: normal;
}
.v2-italik-verzal-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-italik-verzal-11:after {
	content: none;
}
.v2-italik-verzal-12 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-12 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-12 * {
	font-weight: normal;
}
.v2-italik-verzal-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-italik-verzal-12:after {
	content: none;
}
.v2-italik-verzal-13 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-13 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-13 * {
	font-weight: normal;
}
.v2-italik-verzal-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-italik-verzal-13:after {
	content: none;
}
.v2-italik-verzal-14 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-14 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-14 * {
	font-weight: normal;
}
.v2-italik-verzal-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-italik-verzal-14:after {
	content: none;
}
.v2-italik-verzal-15 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-15 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-15 * {
	font-weight: normal;
}
.v2-italik-verzal-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-italik-verzal-15:after {
	content: none;
}
.v2-italik-verzal-16 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-16 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-16 * {
	font-weight: normal;
}
.v2-italik-verzal-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-italik-verzal-16:after {
	content: none;
}
.v2-italik-verzal-17 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-17 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-17 * {
	font-weight: normal;
}
.v2-italik-verzal-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-italik-verzal-17:after {
	content: none;
}
.v2-italik-verzal-18 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-18 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-18 * {
	font-weight: normal;
}
.v2-italik-verzal-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-italik-verzal-18:after {
	content: none;
}
.v2-italik-verzal-19 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-19 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-19 * {
	font-weight: normal;
}
.v2-italik-verzal-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-italik-verzal-19:after {
	content: none;
}
.v2-italik-verzal-20 {
	font-style: italic;
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
}
.state-changes .v2-italik-verzal-20 {
	font-weight: normal;
}
.state-changes .v2-italik-verzal-20 * {
	font-weight: normal;
}
.v2-italik-verzal-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-italik-verzal-20:after {
	content: none;
}
.v2-clan-verzal-3 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-3 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-3 * {
	font-weight: normal;
}
.v2-clan-verzal-3:after {
	content: '***';
	display: inline;
}
.state-changes .v2-clan-verzal-3:after {
	content: none;
}
.v2-clan-verzal-4 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-4 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-4 * {
	font-weight: normal;
}
.v2-clan-verzal-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-clan-verzal-4:after {
	content: none;
}
.v2-clan-verzal-5 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-5 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-5 * {
	font-weight: normal;
}
.v2-clan-verzal-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-clan-verzal-5:after {
	content: none;
}
.v2-clan-verzal-6 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-6 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-6 * {
	font-weight: normal;
}
.v2-clan-verzal-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-clan-verzal-6:after {
	content: none;
}
.v2-clan-verzal-7 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-7 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-7 * {
	font-weight: normal;
}
.v2-clan-verzal-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-clan-verzal-7:after {
	content: none;
}
.v2-clan-verzal-8 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-8 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-8 * {
	font-weight: normal;
}
.v2-clan-verzal-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-clan-verzal-8:after {
	content: none;
}
.v2-clan-verzal-9 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-9 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-9 * {
	font-weight: normal;
}
.v2-clan-verzal-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-clan-verzal-9:after {
	content: none;
}
.v2-clan-verzal-10 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-10 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-10 * {
	font-weight: normal;
}
.v2-clan-verzal-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-clan-verzal-10:after {
	content: none;
}
.v2-clan-verzal-11 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-11 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-11 * {
	font-weight: normal;
}
.v2-clan-verzal-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-clan-verzal-11:after {
	content: none;
}
.v2-clan-verzal-12 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-12 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-12 * {
	font-weight: normal;
}
.v2-clan-verzal-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-clan-verzal-12:after {
	content: none;
}
.v2-clan-verzal-13 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-13 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-13 * {
	font-weight: normal;
}
.v2-clan-verzal-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-clan-verzal-13:after {
	content: none;
}
.v2-clan-verzal-14 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-14 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-14 * {
	font-weight: normal;
}
.v2-clan-verzal-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-clan-verzal-14:after {
	content: none;
}
.v2-clan-verzal-15 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-15 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-15 * {
	font-weight: normal;
}
.v2-clan-verzal-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-clan-verzal-15:after {
	content: none;
}
.v2-clan-verzal-16 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-16 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-16 * {
	font-weight: normal;
}
.v2-clan-verzal-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-clan-verzal-16:after {
	content: none;
}
.v2-clan-verzal-17 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-17 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-17 * {
	font-weight: normal;
}
.v2-clan-verzal-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-clan-verzal-17:after {
	content: none;
}
.v2-clan-verzal-18 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-18 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-18 * {
	font-weight: normal;
}
.v2-clan-verzal-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-clan-verzal-18:after {
	content: none;
}
.v2-clan-verzal-19 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-19 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-19 * {
	font-weight: normal;
}
.v2-clan-verzal-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-clan-verzal-19:after {
	content: none;
}
.v2-clan-verzal-20 {
	text-align: center;	
text-transform: uppercase;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-verzal-20 {
	font-weight: normal;
}
.state-changes .v2-clan-verzal-20 * {
	font-weight: normal;
}
.v2-clan-verzal-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-clan-verzal-20:after {
	content: none;
}
.v2-bold-4 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-4 {
	text-decoration: none;
}
.state-changes .v2-bold-4 * {
	text-decoration: none;
}
.v2-bold-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-bold-4:after {
	content: none;
}
.v2-bold-5 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-5 {
	text-decoration: none;
}
.state-changes .v2-bold-5 * {
	text-decoration: none;
}
.v2-bold-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-bold-5:after {
	content: none;
}
.v2-bold-6 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-6 {
	text-decoration: none;
}
.state-changes .v2-bold-6 * {
	text-decoration: none;
}
.v2-bold-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-bold-6:after {
	content: none;
}
.v2-bold-7 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-7 {
	text-decoration: none;
}
.state-changes .v2-bold-7 * {
	text-decoration: none;
}
.v2-bold-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-bold-7:after {
	content: none;
}
.v2-bold-8 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-8 {
	text-decoration: none;
}
.state-changes .v2-bold-8 * {
	text-decoration: none;
}
.v2-bold-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-bold-8:after {
	content: none;
}
.v2-bold-9 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-9 {
	text-decoration: none;
}
.state-changes .v2-bold-9 * {
	text-decoration: none;
}
.v2-bold-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-bold-9:after {
	content: none;
}
.v2-bold-10 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-10 {
	text-decoration: none;
}
.state-changes .v2-bold-10 * {
	text-decoration: none;
}
.v2-bold-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-bold-10:after {
	content: none;
}
.v2-bold-11 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-11 {
	text-decoration: none;
}
.state-changes .v2-bold-11 * {
	text-decoration: none;
}
.v2-bold-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-bold-11:after {
	content: none;
}
.v2-bold-12 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-12 {
	text-decoration: none;
}
.state-changes .v2-bold-12 * {
	text-decoration: none;
}
.v2-bold-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-bold-12:after {
	content: none;
}
.v2-bold-13 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-13 {
	text-decoration: none;
}
.state-changes .v2-bold-13 * {
	text-decoration: none;
}
.v2-bold-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-bold-13:after {
	content: none;
}
.v2-bold-14 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-14 {
	text-decoration: none;
}
.state-changes .v2-bold-14 * {
	text-decoration: none;
}
.v2-bold-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-bold-14:after {
	content: none;
}
.v2-bold-15 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-15 {
	text-decoration: none;
}
.state-changes .v2-bold-15 * {
	text-decoration: none;
}
.v2-bold-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-bold-15:after {
	content: none;
}
.v2-bold-16 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-16 {
	text-decoration: none;
}
.state-changes .v2-bold-16 * {
	text-decoration: none;
}
.v2-bold-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-bold-16:after {
	content: none;
}
.v2-bold-17 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-17 {
	text-decoration: none;
}
.state-changes .v2-bold-17 * {
	text-decoration: none;
}
.v2-bold-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-bold-17:after {
	content: none;
}
.v2-bold-18 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-18 {
	text-decoration: none;
}
.state-changes .v2-bold-18 * {
	text-decoration: none;
}
.v2-bold-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-bold-18:after {
	content: none;
}
.v2-bold-19 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-19 {
	text-decoration: none;
}
.state-changes .v2-bold-19 * {
	text-decoration: none;
}
.v2-bold-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-bold-19:after {
	content: none;
}
.v2-bold-20 {
	font-weight: bold;
	text-align: center;	
text-decoration: underline;
}
.state-changes .v2-bold-20 {
	text-decoration: none;
}
.state-changes .v2-bold-20 * {
	text-decoration: none;
}
.v2-bold-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-bold-20:after {
	content: none;
}
.v2-italik-4 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-4 {
	font-weight: normal;
}
.state-changes .v2-italik-4 * {
	font-weight: normal;
}
.v2-italik-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-italik-4:after {
	content: none;
}
.v2-italik-5 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-5 {
	font-weight: normal;
}
.state-changes .v2-italik-5 * {
	font-weight: normal;
}
.v2-italik-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-italik-5:after {
	content: none;
}
.v2-italik-6 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-6 {
	font-weight: normal;
}
.state-changes .v2-italik-6 * {
	font-weight: normal;
}
.v2-italik-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-italik-6:after {
	content: none;
}
.v2-italik-7 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-7 {
	font-weight: normal;
}
.state-changes .v2-italik-7 * {
	font-weight: normal;
}
.v2-italik-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-italik-7:after {
	content: none;
}
.v2-italik-8 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-8 {
	font-weight: normal;
}
.state-changes .v2-italik-8 * {
	font-weight: normal;
}
.v2-italik-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-italik-8:after {
	content: none;
}
.v2-italik-9 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-9 {
	font-weight: normal;
}
.state-changes .v2-italik-9 * {
	font-weight: normal;
}
.v2-italik-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-italik-9:after {
	content: none;
}
.v2-italik-10 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-10 {
	font-weight: normal;
}
.state-changes .v2-italik-10 * {
	font-weight: normal;
}
.v2-italik-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-italik-10:after {
	content: none;
}
.v2-italik-11 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-11 {
	font-weight: normal;
}
.state-changes .v2-italik-11 * {
	font-weight: normal;
}
.v2-italik-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-italik-11:after {
	content: none;
}
.v2-italik-12 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-12 {
	font-weight: normal;
}
.state-changes .v2-italik-12 * {
	font-weight: normal;
}
.v2-italik-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-italik-12:after {
	content: none;
}
.v2-italik-13 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-13 {
	font-weight: normal;
}
.state-changes .v2-italik-13 * {
	font-weight: normal;
}
.v2-italik-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-italik-13:after {
	content: none;
}
.v2-italik-14 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-14 {
	font-weight: normal;
}
.state-changes .v2-italik-14 * {
	font-weight: normal;
}
.v2-italik-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-italik-14:after {
	content: none;
}
.v2-italik-15 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-15 {
	font-weight: normal;
}
.state-changes .v2-italik-15 * {
	font-weight: normal;
}
.v2-italik-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-italik-15:after {
	content: none;
}
.v2-italik-16 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-16 {
	font-weight: normal;
}
.state-changes .v2-italik-16 * {
	font-weight: normal;
}
.v2-italik-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-italik-16:after {
	content: none;
}
.v2-italik-17 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-17 {
	font-weight: normal;
}
.state-changes .v2-italik-17 * {
	font-weight: normal;
}
.v2-italik-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-italik-17:after {
	content: none;
}
.v2-italik-18 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-18 {
	font-weight: normal;
}
.state-changes .v2-italik-18 * {
	font-weight: normal;
}
.v2-italik-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-italik-18:after {
	content: none;
}
.v2-italik-19 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-19 {
	font-weight: normal;
}
.state-changes .v2-italik-19 * {
	font-weight: normal;
}
.v2-italik-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-italik-19:after {
	content: none;
}
.v2-italik-20 {
	font-style: italic;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-italik-20 {
	font-weight: normal;
}
.state-changes .v2-italik-20 * {
	font-weight: normal;
}
.v2-italik-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-italik-20:after {
	content: none;
}
.v2-spacija-4 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-4 {
	font-weight: normal;
}
.state-changes .v2-spacija-4 * {
	font-weight: normal;
}
.v2-spacija-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-spacija-4:after {
	content: none;
}
.v2-spacija-5 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-5 {
	font-weight: normal;
}
.state-changes .v2-spacija-5 * {
	font-weight: normal;
}
.v2-spacija-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-spacija-5:after {
	content: none;
}
.v2-spacija-6 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-6 {
	font-weight: normal;
}
.state-changes .v2-spacija-6 * {
	font-weight: normal;
}
.v2-spacija-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-spacija-6:after {
	content: none;
}
.v2-spacija-7 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-7 {
	font-weight: normal;
}
.state-changes .v2-spacija-7 * {
	font-weight: normal;
}
.v2-spacija-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-spacija-7:after {
	content: none;
}
.v2-spacija-8 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-8 {
	font-weight: normal;
}
.state-changes .v2-spacija-8 * {
	font-weight: normal;
}
.v2-spacija-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-spacija-8:after {
	content: none;
}
.v2-spacija-9 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-9 {
	font-weight: normal;
}
.state-changes .v2-spacija-9 * {
	font-weight: normal;
}
.v2-spacija-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-spacija-9:after {
	content: none;
}
.v2-spacija-10 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-10 {
	font-weight: normal;
}
.state-changes .v2-spacija-10 * {
	font-weight: normal;
}
.v2-spacija-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-spacija-10:after {
	content: none;
}
.v2-spacija-11 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-11 {
	font-weight: normal;
}
.state-changes .v2-spacija-11 * {
	font-weight: normal;
}
.v2-spacija-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-spacija-11:after {
	content: none;
}
.v2-spacija-12 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-12 {
	font-weight: normal;
}
.state-changes .v2-spacija-12 * {
	font-weight: normal;
}
.v2-spacija-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-spacija-12:after {
	content: none;
}
.v2-spacija-13 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-13 {
	font-weight: normal;
}
.state-changes .v2-spacija-13 * {
	font-weight: normal;
}
.v2-spacija-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-spacija-13:after {
	content: none;
}
.v2-spacija-14 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-14 {
	font-weight: normal;
}
.state-changes .v2-spacija-14 * {
	font-weight: normal;
}
.v2-spacija-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-spacija-14:after {
	content: none;
}
.v2-spacija-15 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-15 {
	font-weight: normal;
}
.state-changes .v2-spacija-15 * {
	font-weight: normal;
}
.v2-spacija-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-spacija-15:after {
	content: none;
}
.v2-spacija-16 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-16 {
	font-weight: normal;
}
.state-changes .v2-spacija-16 * {
	font-weight: normal;
}
.v2-spacija-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-spacija-16:after {
	content: none;
}
.v2-spacija-17 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-17 {
	font-weight: normal;
}
.state-changes .v2-spacija-17 * {
	font-weight: normal;
}
.v2-spacija-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-spacija-17:after {
	content: none;
}
.v2-spacija-18 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-18 {
	font-weight: normal;
}
.state-changes .v2-spacija-18 * {
	font-weight: normal;
}
.v2-spacija-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-spacija-18:after {
	content: none;
}
.v2-spacija-19 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-19 {
	font-weight: normal;
}
.state-changes .v2-spacija-19 * {
	font-weight: normal;
}
.v2-spacija-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-spacija-19:after {
	content: none;
}
.v2-spacija-20 {
	letter-spacing: 1.2px;
	text-align: center;
	font-weight: bold;
}
.state-changes .v2-spacija-20 {
	font-weight: normal;
}
.state-changes .v2-spacija-20 * {
	font-weight: normal;
}
.v2-spacija-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-spacija-20:after {
	content: none;
}
.v2-clan-4 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-4 {
	font-weight: normal;
}
.state-changes .v2-clan-4 * {
	font-weight: normal;
}
.v2-clan-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-clan-4:after {
	content: none;
}
.v2-clan-5 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-5 {
	font-weight: normal;
}
.state-changes .v2-clan-5 * {
	font-weight: normal;
}
.v2-clan-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-clan-5:after {
	content: none;
}
.v2-clan-6 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-6 {
	font-weight: normal;
}
.state-changes .v2-clan-6 * {
	font-weight: normal;
}
.v2-clan-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-clan-6:after {
	content: none;
}
.v2-clan-7 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-7 {
	font-weight: normal;
}
.state-changes .v2-clan-7 * {
	font-weight: normal;
}
.v2-clan-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-clan-7:after {
	content: none;
}
.v2-clan-8 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-8 {
	font-weight: normal;
}
.state-changes .v2-clan-8 * {
	font-weight: normal;
}
.v2-clan-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-clan-8:after {
	content: none;
}
.v2-clan-9 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-9 {
	font-weight: normal;
}
.state-changes .v2-clan-9 * {
	font-weight: normal;
}
.v2-clan-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-clan-9:after {
	content: none;
}
.v2-clan-10 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-10 {
	font-weight: normal;
}
.state-changes .v2-clan-10 * {
	font-weight: normal;
}
.v2-clan-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-clan-10:after {
	content: none;
}
.v2-clan-11 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-11 {
	font-weight: normal;
}
.state-changes .v2-clan-11 * {
	font-weight: normal;
}
.v2-clan-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-clan-11:after {
	content: none;
}
.v2-clan-12 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-12 {
	font-weight: normal;
}
.state-changes .v2-clan-12 * {
	font-weight: normal;
}
.v2-clan-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-clan-12:after {
	content: none;
}
.v2-clan-13 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-13 {
	font-weight: normal;
}
.state-changes .v2-clan-13 * {
	font-weight: normal;
}
.v2-clan-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-clan-13:after {
	content: none;
}
.v2-clan-14 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-14 {
	font-weight: normal;
}
.state-changes .v2-clan-14 * {
	font-weight: normal;
}
.v2-clan-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-clan-14:after {
	content: none;
}
.v2-clan-15 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-15 {
	font-weight: normal;
}
.state-changes .v2-clan-15 * {
	font-weight: normal;
}
.v2-clan-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-clan-15:after {
	content: none;
}
.v2-clan-16 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-16 {
	font-weight: normal;
}
.state-changes .v2-clan-16 * {
	font-weight: normal;
}
.v2-clan-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-clan-16:after {
	content: none;
}
.v2-clan-17 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-17 {
	font-weight: normal;
}
.state-changes .v2-clan-17 * {
	font-weight: normal;
}
.v2-clan-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-clan-17:after {
	content: none;
}
.v2-clan-18 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-18 {
	font-weight: normal;
}
.state-changes .v2-clan-18 * {
	font-weight: normal;
}
.v2-clan-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-clan-18:after {
	content: none;
}
.v2-clan-19 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-19 {
	font-weight: normal;
}
.state-changes .v2-clan-19 * {
	font-weight: normal;
}
.v2-clan-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-clan-19:after {
	content: none;
}
.v2-clan-20 {
	text-align: center;
	font-weight: bold;
	margin-top: 28px;
}
.state-changes .v2-clan-20 {
	font-weight: normal;
}
.state-changes .v2-clan-20 * {
	font-weight: normal;
}
.v2-clan-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-clan-20:after {
	content: none;
}
.v2-bold-left-4 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-4 {
	text-decoration: none;
}
.state-changes .v2-bold-left-4 * {
	text-decoration: none;
}
.v2-bold-left-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-bold-left-4:after {
	content: none;
}
.v2-bold-left-5 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-5 {
	text-decoration: none;
}
.state-changes .v2-bold-left-5 * {
	text-decoration: none;
}
.v2-bold-left-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-bold-left-5:after {
	content: none;
}
.v2-bold-left-6 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-6 {
	text-decoration: none;
}
.state-changes .v2-bold-left-6 * {
	text-decoration: none;
}
.v2-bold-left-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-bold-left-6:after {
	content: none;
}
.v2-bold-left-7 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-7 {
	text-decoration: none;
}
.state-changes .v2-bold-left-7 * {
	text-decoration: none;
}
.v2-bold-left-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-bold-left-7:after {
	content: none;
}
.v2-bold-left-8 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-8 {
	text-decoration: none;
}
.state-changes .v2-bold-left-8 * {
	text-decoration: none;
}
.v2-bold-left-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-bold-left-8:after {
	content: none;
}
.v2-bold-left-9 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-9 {
	text-decoration: none;
}
.state-changes .v2-bold-left-9 * {
	text-decoration: none;
}
.v2-bold-left-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-bold-left-9:after {
	content: none;
}
.v2-bold-left-10 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-10 {
	text-decoration: none;
}
.state-changes .v2-bold-left-10 * {
	text-decoration: none;
}
.v2-bold-left-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-bold-left-10:after {
	content: none;
}
.v2-bold-left-11 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-11 {
	text-decoration: none;
}
.state-changes .v2-bold-left-11 * {
	text-decoration: none;
}
.v2-bold-left-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-bold-left-11:after {
	content: none;
}
.v2-bold-left-12 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-12 {
	text-decoration: none;
}
.state-changes .v2-bold-left-12 * {
	text-decoration: none;
}
.v2-bold-left-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-bold-left-12:after {
	content: none;
}
.v2-bold-left-13 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-13 {
	text-decoration: none;
}
.state-changes .v2-bold-left-13 * {
	text-decoration: none;
}
.v2-bold-left-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-bold-left-13:after {
	content: none;
}
.v2-bold-left-14 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-14 {
	text-decoration: none;
}
.state-changes .v2-bold-left-14 * {
	text-decoration: none;
}
.v2-bold-left-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-bold-left-14:after {
	content: none;
}
.v2-bold-left-15 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-15 {
	text-decoration: none;
}
.state-changes .v2-bold-left-15 * {
	text-decoration: none;
}
.v2-bold-left-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-bold-left-15:after {
	content: none;
}
.v2-bold-left-16 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-16 {
	text-decoration: none;
}
.state-changes .v2-bold-left-16 * {
	text-decoration: none;
}
.v2-bold-left-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-bold-left-16:after {
	content: none;
}
.v2-bold-left-17 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-17 {
	text-decoration: none;
}
.state-changes .v2-bold-left-17 * {
	text-decoration: none;
}
.v2-bold-left-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-bold-left-17:after {
	content: none;
}
.v2-bold-left-18 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-18 {
	text-decoration: none;
}
.state-changes .v2-bold-left-18 * {
	text-decoration: none;
}
.v2-bold-left-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-bold-left-18:after {
	content: none;
}
.v2-bold-left-19 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-19 {
	text-decoration: none;
}
.state-changes .v2-bold-left-19 * {
	text-decoration: none;
}
.v2-bold-left-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-bold-left-19:after {
	content: none;
}
.v2-bold-left-20 {
	font-weight: bold;
	text-align: left;	
text-decoration: underline;
}
.state-changes .v2-bold-left-20 {
	text-decoration: none;
}
.state-changes .v2-bold-left-20 * {
	text-decoration: none;
}
.v2-bold-left-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-bold-left-20:after {
	content: none;
}
.v2-italik-left-4 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-4 {
	font-weight: normal;
}
.state-changes .v2-italik-left-4 * {
	font-weight: normal;
}
.v2-italik-left-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-italik-left-4:after {
	content: none;
}
.v2-italik-left-5 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-5 {
	font-weight: normal;
}
.state-changes .v2-italik-left-5 * {
	font-weight: normal;
}
.v2-italik-left-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-italik-left-5:after {
	content: none;
}
.v2-italik-left-6 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-6 {
	font-weight: normal;
}
.state-changes .v2-italik-left-6 * {
	font-weight: normal;
}
.v2-italik-left-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-italik-left-6:after {
	content: none;
}
.v2-italik-left-7 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-7 {
	font-weight: normal;
}
.state-changes .v2-italik-left-7 * {
	font-weight: normal;
}
.v2-italik-left-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-italik-left-7:after {
	content: none;
}
.v2-italik-left-8 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-8 {
	font-weight: normal;
}
.state-changes .v2-italik-left-8 * {
	font-weight: normal;
}
.v2-italik-left-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-italik-left-8:after {
	content: none;
}
.v2-italik-left-9 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-9 {
	font-weight: normal;
}
.state-changes .v2-italik-left-9 * {
	font-weight: normal;
}
.v2-italik-left-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-italik-left-9:after {
	content: none;
}
.v2-italik-left-10 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-10 {
	font-weight: normal;
}
.state-changes .v2-italik-left-10 * {
	font-weight: normal;
}
.v2-italik-left-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-italik-left-10:after {
	content: none;
}
.v2-italik-left-11 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-11 {
	font-weight: normal;
}
.state-changes .v2-italik-left-11 * {
	font-weight: normal;
}
.v2-italik-left-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-italik-left-11:after {
	content: none;
}
.v2-italik-left-12 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-12 {
	font-weight: normal;
}
.state-changes .v2-italik-left-12 * {
	font-weight: normal;
}
.v2-italik-left-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-italik-left-12:after {
	content: none;
}
.v2-italik-left-13 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-13 {
	font-weight: normal;
}
.state-changes .v2-italik-left-13 * {
	font-weight: normal;
}
.v2-italik-left-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-italik-left-13:after {
	content: none;
}
.v2-italik-left-14 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-14 {
	font-weight: normal;
}
.state-changes .v2-italik-left-14 * {
	font-weight: normal;
}
.v2-italik-left-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-italik-left-14:after {
	content: none;
}
.v2-italik-left-15 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-15 {
	font-weight: normal;
}
.state-changes .v2-italik-left-15 * {
	font-weight: normal;
}
.v2-italik-left-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-italik-left-15:after {
	content: none;
}
.v2-italik-left-16 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-16 {
	font-weight: normal;
}
.state-changes .v2-italik-left-16 * {
	font-weight: normal;
}
.v2-italik-left-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-italik-left-16:after {
	content: none;
}
.v2-italik-left-17 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-17 {
	font-weight: normal;
}
.state-changes .v2-italik-left-17 * {
	font-weight: normal;
}
.v2-italik-left-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-italik-left-17:after {
	content: none;
}
.v2-italik-left-18 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-18 {
	font-weight: normal;
}
.state-changes .v2-italik-left-18 * {
	font-weight: normal;
}
.v2-italik-left-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-italik-left-18:after {
	content: none;
}
.v2-italik-left-19 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-19 {
	font-weight: normal;
}
.state-changes .v2-italik-left-19 * {
	font-weight: normal;
}
.v2-italik-left-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-italik-left-19:after {
	content: none;
}
.v2-italik-left-20 {
	font-style: italic;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-italik-left-20 {
	font-weight: normal;
}
.state-changes .v2-italik-left-20 * {
	font-weight: normal;
}
.v2-italik-left-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-italik-left-20:after {
	content: none;
}
.v2-spacija-left-4 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-4 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-4 * {
	font-weight: normal;
}
.v2-spacija-left-4:after {
	content: '****';
	display: inline;
}
.state-changes .v2-spacija-left-4:after {
	content: none;
}
.v2-spacija-left-5 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-5 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-5 * {
	font-weight: normal;
}
.v2-spacija-left-5:after {
	content: '*****';
	display: inline;
}
.state-changes .v2-spacija-left-5:after {
	content: none;
}
.v2-spacija-left-6 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-6 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-6 * {
	font-weight: normal;
}
.v2-spacija-left-6:after {
	content: '*\2076';
	display: inline;
}
.state-changes .v2-spacija-left-6:after {
	content: none;
}
.v2-spacija-left-7 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-7 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-7 * {
	font-weight: normal;
}
.v2-spacija-left-7:after {
	content: '*\2077';
	display: inline;
}
.state-changes .v2-spacija-left-7:after {
	content: none;
}
.v2-spacija-left-8 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-8 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-8 * {
	font-weight: normal;
}
.v2-spacija-left-8:after {
	content: '*\2078';
	display: inline;
}
.state-changes .v2-spacija-left-8:after {
	content: none;
}
.v2-spacija-left-9 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-9 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-9 * {
	font-weight: normal;
}
.v2-spacija-left-9:after {
	content: '*\2079';
	display: inline;
}
.state-changes .v2-spacija-left-9:after {
	content: none;
}
.v2-spacija-left-10 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-10 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-10 * {
	font-weight: normal;
}
.v2-spacija-left-10:after {
	content: '*\00B9\2070';
	display: inline;
}
.state-changes .v2-spacija-left-10:after {
	content: none;
}
.v2-spacija-left-11 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-11 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-11 * {
	font-weight: normal;
}
.v2-spacija-left-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-spacija-left-11:after {
	content: none;
}
.v2-spacija-left-12 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-12 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-12 * {
	font-weight: normal;
}
.v2-spacija-left-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-spacija-left-12:after {
	content: none;
}
.v2-spacija-left-13 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-13 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-13 * {
	font-weight: normal;
}
.v2-spacija-left-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-spacija-left-13:after {
	content: none;
}
.v2-spacija-left-14 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-14 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-14 * {
	font-weight: normal;
}
.v2-spacija-left-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-spacija-left-14:after {
	content: none;
}
.v2-spacija-left-15 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-15 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-15 * {
	font-weight: normal;
}
.v2-spacija-left-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-spacija-left-15:after {
	content: none;
}
.v2-spacija-left-16 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-16 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-16 * {
	font-weight: normal;
}
.v2-spacija-left-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-spacija-left-16:after {
	content: none;
}
.v2-spacija-left-17 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-17 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-17 * {
	font-weight: normal;
}
.v2-spacija-left-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-spacija-left-17:after {
	content: none;
}
.v2-spacija-left-18 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-18 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-18 * {
	font-weight: normal;
}
.v2-spacija-left-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-spacija-left-18:after {
	content: none;
}
.v2-spacija-left-19 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-19 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-19 * {
	font-weight: normal;
}
.v2-spacija-left-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-spacija-left-19:after {
	content: none;
}
.v2-spacija-left-20 {
	letter-spacing: 1.2px;
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-spacija-left-20 {
	font-weight: normal;
}
.state-changes .v2-spacija-left-20 * {
	font-weight: normal;
}
.v2-spacija-left-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-spacija-left-20:after {
	content: none;
}
.v2-clan-left-11 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-11 {
	font-weight: normal;
}
.state-changes .v2-clan-left-11 * {
	font-weight: normal;
}
.v2-clan-left-11:after {
	content: '*\00B9\00B9';
	display: inline;
}
.state-changes .v2-clan-left-11:after {
	content: none;
}
.v2-clan-left-12 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-12 {
	font-weight: normal;
}
.state-changes .v2-clan-left-12 * {
	font-weight: normal;
}
.v2-clan-left-12:after {
	content: '*\00B9\00B2';
	display: inline;
}
.state-changes .v2-clan-left-12:after {
	content: none;
}
.v2-clan-left-13 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-13 {
	font-weight: normal;
}
.state-changes .v2-clan-left-13 * {
	font-weight: normal;
}
.v2-clan-left-13:after {
	content: '*\00B9\00B3';
	display: inline;
}
.state-changes .v2-clan-left-13:after {
	content: none;
}
.v2-clan-left-14 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-14 {
	font-weight: normal;
}
.state-changes .v2-clan-left-14 * {
	font-weight: normal;
}
.v2-clan-left-14:after {
	content: '*\00B9\2074';
	display: inline;
}
.state-changes .v2-clan-left-14:after {
	content: none;
}
.v2-clan-left-15 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-15 {
	font-weight: normal;
}
.state-changes .v2-clan-left-15 * {
	font-weight: normal;
}
.v2-clan-left-15:after {
	content: '*\00B9\2075';
	display: inline;
}
.state-changes .v2-clan-left-15:after {
	content: none;
}
.v2-clan-left-16 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-16 {
	font-weight: normal;
}
.state-changes .v2-clan-left-16 * {
	font-weight: normal;
}
.v2-clan-left-16:after {
	content: '*\00B9\2076';
	display: inline;
}
.state-changes .v2-clan-left-16:after {
	content: none;
}
.v2-clan-left-17 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-17 {
	font-weight: normal;
}
.state-changes .v2-clan-left-17 * {
	font-weight: normal;
}
.v2-clan-left-17:after {
	content: '*\00B9\2077';
	display: inline;
}
.state-changes .v2-clan-left-17:after {
	content: none;
}
.v2-clan-left-18 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-18 {
	font-weight: normal;
}
.state-changes .v2-clan-left-18 * {
	font-weight: normal;
}
.v2-clan-left-18:after {
	content: '*\00B9\2078';
	display: inline;
}
.state-changes .v2-clan-left-18:after {
	content: none;
}
.v2-clan-left-19 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-19 {
	font-weight: normal;
}
.state-changes .v2-clan-left-19 * {
	font-weight: normal;
}
.v2-clan-left-19:after {
	content: '*\00B9\2079';
	display: inline;
}
.state-changes .v2-clan-left-19:after {
	content: none;
}
.v2-clan-left-20 {
	text-align: left;
	font-weight: bold;
}
.state-changes .v2-clan-left-20 {
	font-weight: normal;
}
.state-changes .v2-clan-left-20 * {
	font-weight: normal;
}
.v2-clan-left-20:after {
	content: '*\00B2\2070';
	display: inline;
}
.state-changes .v2-clan-left-20:after {
	content: none;
}

/*
	Generisano END
*/



.state-changes .unbold-change {
        font-weight: normal!important;
}
.state-changes .unbold-change * {
        font-weight: normal!important;
}
.state-changes .unitalic-change {
        text-decoration: none;
}
.state-changes .hide-change {
        display: none!important;
}
.state-changes .odluka-zakon {
	font-weight: bold; 
	text-align: center;
	margin:24px 0 10px 0;
	text-decoration: underline;
}

/* .state-changes .odluka-zakon {
} */

.state-menu * {
	display: none;
}
.force-display-block {
	display: block!important;
}
.force-display-none {
	display: none!important;
}

.state-menu .act-menu-item {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	font-weight: normal!important;
}
.state-menu .odluka-zakon{
	cursor: pointer;
	margin: 2px;
	color: #0000FF;
	font-weight: normal!important;
}
.state-menu .naslov {
	cursor: pointer;
	margin: 2px;
	color: #0000FF;
	font-weight: normal!important;
}
.state-menu .clan {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	font-weight: normal!important;
}
/*
 dodato 20.oct
*/
.state-menu .italik {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	letter-spacing: 0px!important;
	font-style: normal!important;
	font-weight: normal!important;
	font-weight: normal!important;
}
.state-menu *[class^="v2-clan-"] {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	font-weight: normal!important;
}
.state-menu *[class^="v2-spacija-"] {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	letter-spacing: 0px!important;
	font-weight: normal!important;
}
.state-menu *[class^="v2-italik-"] {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	letter-spacing: 0px!important;
	font-style: normal!important;
	font-weight: normal!important;
}
.state-menu *[class^="v2-bold-"] {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	letter-spacing: 0px!important;
	font-style: normal!important;
	font-weight: normal!important;
}
.state-menu *[class^="v2-naslov-"] {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	letter-spacing: 0px!important;
	font-style: normal!important;
	font-weight: normal!important;
}
.state-menu .v2-underline {
	cursor: pointer;
	margin: 2px;
	text-align: left;
	color: #0000FF;
	font-weight: normal!important;
}
.state-menu .bold {
	cursor: pointer;
	margin: 2px;
	margin-left: 16px!important;
	text-align: left;
	color: #0000FF;
	font-weight: normal!important;
}

/*
izmena 20 okt
.state-menu .clan-margin {
	margin-left: 32px!important;
}
*/

.state-menu .clan-margin {
	margin-left: 48px!important;
}
/*
 dodato 20 okt
*/
.state-menu .italik.clan-margin {
	margin-left: 32px!important;
}

.state-menu br {
	display: block!important;
}

/* .resize-type-em {
} */
.normalize-line-height * {
	text-indent: 0px!important;
}
.law-size-0 {
	font-size: 16px;
}

.law-size-0 * {
	line-height: 19px!important;
}



body.resize-type-em.font-size-80 {
	font-size: 8px;
	line-height: 10px;
}
body.resize-type-em.font-size-85 {
	font-size: 9px;
	line-height: 11px;
}
body.resize-type-em.font-size-90 {
	font-size: 10px;
	line-height: 12px;
}
body.resize-type-em.font-size-95 {
	font-size: 11px;
	line-height: 13px;
}
body.resize-type-em.font-size-100 {
	font-size: 12px;
	line-height: 14px;
}
body.resize-type-em.font-size-105 {
	font-size: 13px;
	line-height: 15px;
}
body.resize-type-em.font-size-110 {
	font-size: 14px;
	line-height: 16px;
}
body.resize-type-em.font-size-115 {
	font-size: 15px;
	line-height: 18px;
}
body.resize-type-em.font-size-120 {
	font-size: 16px;
	line-height: 19px;
}


/* .h4, h4 {
    font-size: 18px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}
ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
}
body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 0.75rem;
    line-height: 1.42857143;
/*    color: #333;
*/
/*element {
    font-size: 100%;
}
.modal-body{
    position:relative;padding:15px
} */
/*
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    float: left;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-lg-12 {
    width: 100%;
}
.col-sm-12 {
    width: 100%;
}
.col-sm-10 {
    width: 83.33333333%;
}
.col-sm-offset-0 {
    margin-left: 0;
}
.col-lg-2 {
    width: 16.66666667%;
}
.col-lg-8 {
    width: 66.66666667%;
}
.col-lg-offset-2 {
    margin-left: 16.66666667%;
}
*/
.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
}
.list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}
.nav-tabs-green > li {
    background: #dddddd;
    height: 30px;
    vertical-align: middle;
    line-height: 30px;
    color: #0e9606;
    margin: 0 2px;
    padding: 0 15px;
    font-weight: 600;
}
.panel-group {
    margin-bottom: 20px;
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.panel-default {
    border-color: #ddd;
}
.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}
.panel-group .panel + .panel {
    margin-top: 5px;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
#topPanelTitle {
    color: #ffffff;
    padding-left: 15px;
    padding-top: 10px;
    margin: 0;
    margin-bottom: 10px;
}
#topPanel {
    background: #f5f5f5;
    background: #9c9c9c;
    background: -webkit-linear-gradient(top, #9c9c9c 0%, #cbcbcb 100%);
    background: -moz-linear-gradient(top, #9c9c9c 0%, #cbcbcb 100%);
    background: -ms-linear-gradient(top, #9c9c9c 0%, #cbcbcb 100%);
    background: -o-linear-gradient(top, #9c9c9c 0%, #cbcbcb 100%);
    background: linear-gradient(to bottom, #9c9c9c 0%, #cbcbcb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9c9c9c', endColorstr='#cbcbcb', GradientType=0 );
    min-height: 80px;
    border-bottom: 3px solid #ffffff;
    margin-bottom: 5px;
}
.table-panel-green1 {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #dddddd;
    border-radius: 4px;
    box-shadow: none;
    margin-top: 5px;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}
.panel-title-green {
    font-size: 14px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel-heading-green {
    background: #0e9606;
    background: -webkit-linear-gradient(top, #0e9606 0%, #026105 100%);
    background: -moz-linear-gradient(top, #0e9606 0%, #026105 100%);
    background: -ms-linear-gradient(top, #0e9606 0%, #026105 100%);
    background: -o-linear-gradient(top, #0e9606 0%, #026105 100%);
    background: linear-gradient(to bottom, #0e9606 0%, #026105 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e9606', endColorstr='#026105', GradientType=0 );
    cursor: pointer;
    color: #ffffff !important;
    border-color: #024b03;
}
.panel-group .panel-heading {
    border-bottom: 0;
}
.panel-default > .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
/* .h5, h5 {
    font-size: 14px;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
p {
    margin: 0 0 10px;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
} */
.view-act-primary {
    overflow: auto !important;
    padding: 5px;
    border-top: 2px solid #cccccc;
    height: -webkit-calc(100% - 81px);
    height: -moz-calc(100% - 81px);
    height: -o-calc(100% - 81px);
    height: calc(100% - 81px);
}

.panel > .list-group, .panel > .panel-collapse > .list-group {
    margin-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 1px solid #ddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ddd;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
}
.list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.panel > .list-group .list-group-item, .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child, .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.collapse {
    display: none;
}
.collapse.in {
    display: block;
}
/* a {
    color: #337ab7;
    text-decoration: none;
} */
/*


 *   додато приликом генерисања верзије v7 апликације ДАРА
 *   =====================================================
 */

 


/* додато због структуре прописа */
.state-menu .clan-margin * {
	text-align: left;
	text-decoration: none!important;
	letter-spacing: 0!important;
	font-style: normal!important;
}

.force-display-block {
    text-align: left!important;
    font-weight: normal!important;
    margin-top: 0px!important;
}




/* против "плавих линкова" код индексираних InDesign-фајлова */
/*
a#anchor-anchor,a#anchor-1-anchor,a#anchor-2-anchor,a#anchor-3-anchor,a#anchor-4-anchor,a#anchor-5-anchor,a#anchor-6-anchor,a#anchor-7-anchor,a#anchor-8-anchor,a#anchor-9-anchor,a#anchor-10-anchor,a#anchor-11-anchor,a#anchor-12-anchor,a#anchor-13-anchor,a#anchor-14-anchor,a#anchor-15-anchor,a#anchor-16-anchor,a#anchor-17-anchor,a#anchor-18-anchor,a#anchor-19-anchor,a#anchor-20-anchor,a#anchor-21-anchor,a#anchor-22-anchor,a#anchor-23-anchor,a#anchor-24-anchor,a#anchor-25-anchor,a#anchor-26-anchor,a#anchor-27-anchor,a#anchor-28-anchor,a#anchor-29-anchor,a#anchor-30-anchor,a#anchor-31-anchor,a#anchor-32-anchor,a#anchor-33-anchor,a#anchor-34-anchor,a#anchor-35-anchor,a#anchor-36-anchor,a#anchor-37-anchor,a#anchor-38-anchor,a#anchor-39-anchor,a#anchor-40-anchor,a#anchor-41-anchor,a#anchor-42-anchor,a#anchor-43-anchor,a#anchor-44-anchor,a#anchor-45-anchor,a#anchor-46-anchor,a#anchor-47-anchor,a#anchor-48-anchor,a#anchor-49-anchor,a#anchor-50-anchor,a#anchor-51-anchor,a#anchor-52-anchor,a#anchor-53-anchor,a#anchor-54-anchor,a#anchor-55-anchor,a#anchor-56-anchor,a#anchor-57-anchor,a#anchor-58-anchor,a#anchor-59-anchor,a#anchor-60-anchor,a#anchor-61-anchor,a#anchor-62-anchor,a#anchor-63-anchor,a#anchor-64-anchor,a#anchor-65-anchor,a#anchor-66-anchor,a#anchor-67-anchor,a#anchor-68-anchor,a#anchor-69-anchor,a#anchor-70-anchor,a#anchor-71-anchor,a#anchor-72-anchor,a#anchor-73-anchor,a#anchor-74-anchor,a#anchor-75-anchor,a#anchor-76-anchor,a#anchor-77-anchor,a#anchor-78-anchor,a#anchor-79-anchor,a#anchor-80-anchor,a#anchor-81-anchor,a#anchor-82-anchor,a#anchor-83-anchor,a#anchor-84-anchor,a#anchor-85-anchor,a#anchor-86-anchor,a#anchor-87-anchor,a#anchor-88-anchor,a#anchor-89-anchor,a#anchor-90-anchor,a#anchor-91-anchor,a#anchor-92-anchor,a#anchor-93-anchor,a#anchor-94-anchor,a#anchor-95-anchor,a#anchor-96-anchor,a#anchor-97-anchor,a#anchor-98-anchor,a#anchor-99-anchor,a#anchor-100-anchor {color:#000;}
*/

a[id^=anchor-] {color:#000;}

/*mora da ostane*/
.naslovMML {font-size: 0.92rem;  font-weight: bold; text-align: center; margin-top: 1.5rem; margin-bottom:1.5rem; line-height:1.2rem;}/*MML 17.08.2016*/




/* за приказивање широких табела као она што је на http://www.pravno-informacioni-sistem.rs/SlGlasnikPortal/eli/rep/sgrs/vlada/odluka/2019/18/1/sg */
.siroka-tabela {
	width:100%;
	overflow-x:auto;
}

