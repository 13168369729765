.itemList {
    position: static !important;
    margin: 0px !important;
    padding: 0px !important;
    text-align: left !important;
    text-indent:0px !important;
    float: none !important;
    font-family: Arial, Helvetica, sans-serif !important;
    height: auto !important;
    width: auto !important;
  }

  .highlight {
    background:#FFFFBB;
    border-bottom: 1px dotted #440000;
}

.new {
  background-color:red;
  color: white;
}